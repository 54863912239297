<template>
    <div id="top-header">
      <div class="title">
        <h1 class="mt-8 mb-5">{{title}}</h1>
      </div>
      <div class="desc_container">
        <div class="subtitle">
        </div>
        <div class="d-flex justify-end">
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Title',
  props: ['title'],
  data: () => ({
  }),
}
</script>

<style>
.subtitle {
  height:80px;
  margin: 20px 0;
}
.desc_container {
  background: url('/bg3.jpg');
  background-size: cover;
  opacity: 1;
  min-height: 200px;
  border-radius: 10px;
}
</style>
