<template>
  <v-container class="px-0">
    <v-row class="text-center">
      <v-col
        class="px-0 text-left"
        cols="12"
      >
        <Pond v-if="!pond"/>
        <Samples v-if="pond"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import Pond from './Pond';
import Samples from './Samples';

export default {
  name: 'Main',

  components: {
    Pond,
    Samples,
  },
  computed: {
    ...mapState(["pond"]),
  },
  data: () => ({
    menu: 0,
  }),
}
</script>
<style>
.menu {
  position: relative;
  
}
.menu::before {
  position: absolute;
  border-radius: 3px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url('https://www.minnowtech.com/uploads/1/1/0/3/110367091/ezgif-com-webp-to-png-1_orig.png'); 
  background-size: cover;
  opacity: 0.3;
}

.menu .menu-btn {
  padding: 0;
}
.menu-btn button {
  font-size: 0.8rem!important;
}

</style>