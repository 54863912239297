  <template>
<div>
  <!-- new pond -->
  <template v-if="status === 1 || updateid">
    <Title :title="updateid ? `Update ${pond.farm_name}, Pond ${pond.pond_id}, ${pond.date}` : 'New Pond Entry'">
      <v-btn color="primary" @click="changeToList()" class="pr-6">
        Pond List
      </v-btn>      
    </Title>

    <h4>Farm:</h4>
    <v-select
      v-model="farm"
      :items="farm_list"
      item-text="name"
      item-value="id"
      label="select Farm"
      @change="changeFarm"
      dense
      solo
      class="farmlist"
      :disabled="updateid!=null"
    ></v-select>

    <h4>Pond ID</h4>
    <v-text-field
      label="Pond ID"
      v-model="pond_id"
      placeholder="Please input Pond ID."
      solo
      class="mt-0"
    >
    </v-text-field>

    <h4>Stocking Date(dd/mm/yyyy):</h4>
    <v-date-picker v-model="date"></v-date-picker>

    <h4>Pond Size (m²): <span class="required">required</span></h4>
    <v-text-field
        label="Pond Size"
        v-model="pond_size"
        placeholder="Please input sample size."
        solo
        class="mt-0"
    >
    </v-text-field>
    <h4>Stocking Size (g): <span class="required">required</span></h4>
    <v-text-field
        label="size"
        v-model="shrimp_size"
        placeholder="Please input stocking size."
        solo
        class="mt-0"
    >
    </v-text-field>
    <h4>Stocking Number: <span class="required">required</span></h4>
    <v-text-field
        label="number"
        v-model="shrimp_number"
        placeholder="Please input stocking number."
        solo
        class="mt-0"
    >
    </v-text-field>
    <v-btn large 
      color="primary"
      class="flex-grow-1"
      @click="addPond()"
    >
        Submit
    </v-btn>
  </template>

  <!-- ponds lists -->
  <template v-else>
    <Title title="Pond List">
      <v-row class="pa-5">
        <v-col
          class="d-flex"
          :cols="isMobile ? 12 : 4"
        >
        <!-- Farmer Side -->
        <!-- <template v-if="!isMinnowtech">
          <div class="farmname">{{user_info.farmname}}</div>
        </template> -->
        <!-- Minnowtech Side -->
        <template v-if="isMinnowtech">
          <v-select
            v-model="farm"
            :items="farm_list"
            item-value="id"
            @change="changeFarm"
            dense
            hide-details
            solo
            :class="isMobile ? '' : 'farmlist'"
          >
            <template v-slot:item="{ item }">
              <img v-if="item.id" :src="item.avatar" class="avatar"> {{item.name}} {{item.id ? `- ${item.active_pond_count} Active` : ""}} 
            </template>
            <template v-slot:selection="{ item }">
              <img v-if="item.id" :src="item.avatar" class="avatar"> {{item.name}} {{item.id ? `- ${item.active_pond_count} Active` : ""}} 
            </template>
          </v-select>
        </template>
        </v-col>

        <v-col
          class="d-flex justify-start"
          :cols="isMobile ? 12 : 4"
        >
          <v-btn v-if="isMinnowtech" :class="isMobile ? 'addpond-mobile': ''" color="accent" @click="ChangeToAddPond()">
            Add Pond
          </v-btn>
        </v-col>
      </v-row>
    </Title>

    <div>
      <v-radio-group row v-model="show" class="d-flex my-2">
        <v-radio
          v-if="isMinnowtech"
          :value="SHOW.REPORT"
          :label="`New Reports (${newReportCount})`"
          @click="setFiltered(SHOW.REPORT)"
          class="flex-grow-1 mt-0 ml-4"
        ></v-radio>
        <v-radio
          :value="SHOW.ALL"
          :label="`Show All`"
          @click="setFiltered(SHOW.ALL)"
          class="flex-grow-1 mt-0 ml-4"
        ></v-radio>
        <v-radio
          :value="SHOW.ACTIVE"
          :label="`Show Active Only`"
          @click="setFiltered(SHOW.ACTIVE)"
          class="flex-grow-1 mt-0 ml-4"
        ></v-radio>
        <v-radio
          :value="SHOW.COMPLETED"
          :label="`Show Completed Only`"
          @click="setFiltered(SHOW.COMPLETED)"
          class="flex-grow-1 mt-0 ml-4"
        ></v-radio>
      </v-radio-group>
    </div>
    <v-row style="flex: 0 0 24px">
      <v-col cols="12" class="text-right py-0 theme--light v-messages">
        {{page}}/{{pages}} Pages
      </v-col>
    </v-row>
    <v-row>
    <v-simple-table class="mt-0" style="width: 100%">
      <template #default>
        <thead >
          <tr>
            <th class="text-left custom-header" style="color: white">
              <div :class="'sort-field ' + sortClass('pond_id')" @click="setSort('pond_id')">
                <span style="color: white">Pond Name {{isMinnowtech ? '(UID)' : ''}}</span>
              </div>
            </th>
            <th class="text-left custom-header">
              <div :class="'sort-field ' + sortClass('date')" @click="setSort('date')">
                <span style="color: white">Stocking Date</span>
              </div>
            </th>
            <th class="text-right custom-header">
              <div :class="'sort-field ' + sortClass('pond_size')" @click="setSort('pond_size')">
                <span style="color: white">Pond Size (m²)</span>
              </div>
            </th>
            <th v-if="isMinnowtech" class="text-right custom-header">
              <span style="color: white">Stocking Size (g)</span>
            </th>
            <th v-if="isMinnowtech" class="text-right custom-header">
              <span style="color: white">Stocking Number</span>
            </th>
            <th class="text-right custom-header">
              <div :class="'sort-field ' + sortClass('days_of_culture')" @click="setSort('days_of_culture')">
                <span style="color: white">Days of Culture</span>
              </div>
            </th>
            <th v-if="!isMinnowtech" class="empty custom-header"></th>
            <th class="text-left custom-header">
              <span style="color: white">Weeks of Culture</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loadingStatus">
            <tr>
              <td class="nodata" colspan="7" align="center">
                <v-progress-circular
                  indeterminate
                  color="amber"
                ></v-progress-circular>
              </td>
            </tr>
          </template>
          <template v-else-if="!pond_list.length">
            <tr
              class="pointer"
            >
              <td colspan="7" align="center" class="nodata">
              No data
              </td>
            </tr>
          </template>

          <template v-else>
            <template v-for="(item, i) in pond_list">
              <tr
                :key="'pond_'+item.id"
                @click="setPond(item)"
                :class="'pointer ' + (item.completed ? 'completed' : (item.harveststarted ? 'harveststarted' : ''))"
              >
                <td class="align-center">
                  <strong :class="'big-font ' + duplicated(i)">{{ item.pond_id }}</strong>
                  <span v-if="isMinnowtech" :class="'farm '+(item.farmColor)">{{getFarmName(item.farm)}}</span>
                  <span v-if="isMinnowtech" class="uid">({{ item.id }})</span>
                </td>
                <td>
                  {{ item.date | formatDate }}
                </td>
                <td class="text-right">
                  {{ item.pond_size | formatComma }}
                </td>
                <td v-if="isMinnowtech" class="text-right">
                  {{ item.shrimp_size | formatComma }}
                </td>
                <td v-if="isMinnowtech" class="text-right">
                  {{ item.shrimp_number | formatComma }}
                </td>
                <td class="text-right">
                  {{ getCulture(item) }}
                </td>
                <td v-if="!isMinnowtech" class="empty">
                </td>
                <td class="ml-3">
                  <div class="week">
                    W{{ getWOC(item) }} 
                  </div>
                  <div class="pond-info">
                    <v-icon
                      small
                      color="red lighten-3"
                    >
                      mdi-chart-line
                    </v-icon>
                    {{ item.results_count }}
                    
                    <v-icon
                      small
                      color="orange lighten-3"
                    >
                      mdi-database
                    </v-icon>
                    {{ item.samples_count }}
                    <!-- <v-icon
                      small
                      color="blue lighten-2"
                    >
                      mdi-radar
                    </v-icon>
                    {{ item.device.length }} -->
                  </div>

                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    </v-row>
    <v-row style="flex: 0 0 55px" class="justify-center pt-3 mb-6">
        <v-pagination
          v-model="page"
          :length="pages"
          @input="handlePageChange"
          circle
        ></v-pagination>
      </v-row>
  </template>
</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from "vuex";

import Title from "@/components/Title";
import tokenAxios from "@/components/tokenAxios";
import moment from 'moment';
import Swal from "sweetalert2";
  
const ROW_IN_PAGE = 20;

export default {
  name: 'Pond',
  // props: ['status'],  // 1: new, null: list
  components: { Title },
  computed: {
    ...mapState(["user_info", "isMinnowtech", "pond", "isMobile", "loadingStatus"]),
  },
  data() {
    return {
      API_SERVER: process.env.VUE_APP_API_SERVER,
      updateid: this.$route.params.updateid,
      page: 1,
      pages: 1,
      farm: null,  // farm selected
      farm_list: [],  // farm list
      pond_list: [],  // pond list
      status: 0,    // 1: new, null: list
      pond_id: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      pond_size: null,
      shrimp_size: null,
      shrimp_number: null,
      newReportCount: 0,
      SHOW: Object.freeze({
        ALL: 0,
        ACTIVE: 1,
        COMPLETED: 2,
        REPORT: 3,
      }),
      show: 1,
      sortBy: 'pond_id',
      sortDesc: true,
    };
  },

  created() {
    this.$debug('created...', this.$route.name, this.pond, this.user_info.level, this.user_info.farm)

    // when refresh on updatepond
    if( this.$route.name === 'updatepond' && this.pond == null) this.$router.push('/ponds')

    // Minnowtech Side : show all farms
    if(this.isMinnowtech ) {
      this.listFarms();

      // Back from the pond details
      if (this.pond) {
        console.log('Back from the pond details', this.pond)
        this.farm = this.pond.farm
        this.listPonds(this.pond.farm, 1, this.show, this.sortBy, this.sortDesc);
      
      } else {
        this.listPonds('', 1, this.show, this.sortBy, this.sortDesc);
      }
    // Farmer Side : show only user's farm
    } else {
      this.farm = this.user_info.farm
      this.listPonds(this.user_info.farm, 1, this.show, this.sortBy, this.sortDesc);
    }
  },
  mounted () {
    this.$debug('mounted', this.$route, this.$route.name)
    // when $route change to update
    this.$debug( 'watch...', this.$route, this.pond )
    if( this.$route.name === 'updatepond') {
      this.farm = this.pond.farm;
      this.pond_id = this.pond.pond_id;
      this.date = this.pond.date;
      this.pond_size = this.pond.pond_size;
      this.shrimp_size = this.pond.shrimp_size;
      this.shrimp_number = this.pond.shrimp_number;
    }

    this.countNewResults()
  },
  methods: {
    // Sort
    setSort(sortBy) {
      console.log('..', this.sortBy, this.sortDesc)
      var sortDesc;
      // when sortBy is already sortBy
      if (this.sortBy == sortBy ) {
        sortDesc = !this.sortDesc
      // when sortBy is not sortBy
      } else {
        sortDesc = false
      }
      this.listPonds(this.farm, this.page, this.show, sortBy, sortDesc)
      this.sortBy = sortBy
      this.sortDesc = sortDesc
    },

    // get sort class
    sortClass(sortBy) {
      if (this.sortBy === sortBy ) {
        if (this.sortDesc) return 'desc'
        else return 'asc'
      }
      return ''
    },

    // check if pond id if same as previous one
    duplicated(i) {
      if( i!=0 && this.pond_list[i].pond_id == this.pond_list[i-1].pond_id ) {
        if( this.pond_list[i].completed ) return 'duplicated_completed';
        else return 'duplicated';
      }
    },

    // Change to List
    changeToList() {
      this.$router.push('/ponds').catch(() => {
        this.farm_list.unshift({id: null, name: "Show All Farms"});
        this.status = 0;
      })
    },

    // Change to addpond
    ChangeToAddPond() {
      this.farm_list.shift();      
      this.status = 1;  // New
    },

    // Get Farm Name
    getFarmName(farm_id) {
      const farm = this.farm_list.find(o => o.id===farm_id)
      if(farm) return farm.name
      else return ""
    },

    // Show Rows according to radio button
    setFiltered(show) {
      console.log('setFiltered', this.farm)
      if( show === this.SHOW.ACTIVE ) {
        this.listPonds(this.farm, 1, this.SHOW.ACTIVE, this.sortBy, this.sortDesc);
      } else if( show === this.SHOW.COMPLETED ) {
        this.listPonds(this.farm, 1, this.SHOW.COMPLETED, this.sortBy, this.sortDesc);
      } else if( show === this.SHOW.REPORT ) {
        this.listPonds(this.farm, 1, this.SHOW.REPORT, this.sortBy, this.sortDesc);
      } else {
        this.listPonds(this.farm, 1, this.SHOW.ALL, this.sortBy, this.sortDesc);
      }
    },

    // Get days of Culture
    getCulture(item) {
      const diff = (item.completed ? moment(item.completed) : moment()).diff(moment(item.date), 'days');
      return (diff === 0 || diff === 1) ? diff+' day' : diff+' days';
    },

    // Get Week of Culture
    getWOC(item) {
      const diff = (item.completed ? moment(item.completed) : moment()).diff(moment(item.date), 'days');
      const woc = Math.floor( (diff-1) / 7 ) + 1;
      return woc;
    },

    // When click row of pond list, set pond to the store
    setPond(item) {
      this.$debug( 'setPond', item )
      this.$store.commit("pond", item);

      // Minnowtech Side
      if(this.isMinnowtech) {
        if(this.pond.results_new_count) this.$router.push('/farmer_report_new')
        else this.$router.push('/samples')
      // Farmer Side
      } else {
        this.$router.push('/farmersamples')
      }
    },

    // List Farms
    async listFarms(page) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, this.user_info )
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/farm/?format=json` + (page ? `&page=${this.page}` : "")
          );
          this.$debug("get farm lists", response);
          if (response.status === 200 && response.data.length) {
            const newlist = response.data.map(o=> {
              if( !o.avatar ) {
                o.avatar = "https://farm-avatar.s3.amazonaws.com/minnowtech.jpg";
              }
              return o;
            })
            this.farm_list = [{id: null, name: `Show All ${newlist.length} Farms`}];
            this.farm_list.push(...newlist);
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      }
    },

    // List Samples
    handlePageChange(page) {
      if( this.pages == 1 ) return;
      this.$debug( 'handlePageChange', this.farm, page)
       
      // Minnowtech Side : show all farms
      if(this.isMinnowtech ) {
        this.listPonds(this.farm, page, this.show, this.sortBy, this.sortDesc);

      // Farmer Side : show only user's farm
      } else {
        this.listPonds(this.user_info.farm, page, this.show, this.sortBy, this.sortDesc);
      }
    },

    // List all Ponds
    async listPonds(farm, page, show, sortBy, sortDesc) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/pond/?format=json`
               + (farm ? `&farm=${farm}` : "")
               + (page ? `&page=${page}` : "")
               + (show ? `&show=${show}` : "")
               + (sortBy ? `&sortBy=${sortBy}` : "")
               + (sortDesc ? `&sortDesc=1` : "")
          );
          this.$debug("get pond list", response);
          if (response.status === 200) {
            // change the color of farm
            let pre = ''
            let farmColor = ''
            this.pond_list = response.data.results.map( (o, i) => {
              if(i===0 || o.farm !== pre) {
                if( !farmColor ) farmColor = 'farm2'
                else farmColor = ''
              }
              pre = o.farm
              o.farmColor = farmColor
              return o
            })

            this.page = page
            this.pages = Math.ceil( response.data.count / ROW_IN_PAGE );
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0)
      }
    },

    // get the count of new Results
    async countNewResults(farm='') {
      try {
          this.$debug( "API_SERVER", this.API_SERVER )
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/newreportcount/` + (farm ? `?farm=${farm}` : '')
          );
          this.$debug("get pond list", response);
          if (response.status === 200) {
            this.newReportCount = response.data;
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      }
    },

    // Change Farm
    changeFarm(o) {
      this.$debug("changeFarm", o)
      this.listPonds(o, 1, this.show)
      this.countNewResults(o)
    },

    // Add Pond
    async addPond() {
      try {
        this.$debug("this.pond_list.findIndex(o=> o.pond_id === this.pond_id)", this.pond_list, this.pond_list.findIndex(o=> o.pond_id==this.pond_id))
        if( !this.farm ) {
          Swal.fire(
            "Please select a farm",
            "",
            "error"
          );
          return;
        }

        if( !this.pond_id ) {
          Swal.fire(
            "Pond ID is required",
            "",
            "error"
          );
          return;
        }

        if( !this.pond_size ) {
          Swal.fire(
            "Pond Size is required",
            "",
            "error"
          );
          return;
        }

        if( !this.shrimp_size ) {
          Swal.fire(
            "Stocking Size is required",
            "",
            "error"
          );
          return;
        }

        if( !this.shrimp_number ) {
          Swal.fire(
            "Stocking Number is required",
            "",
            "error"
          );
          return;
        }

        // create Pond
        if( !this.updateid ) {
          if( this.pond_list.findIndex(o=> (o.pond_id==this.pond_id && o.date==this.date) ) !== -1 ) {
            Swal.fire(
              `Pond ID on ${Vue.filter('formatDate')(this.date)} exists`,
              "",
              "error"
            );
            return;
          }
        }

        var formData = new FormData();
        formData.append("farm", this.farm);
        formData.append("pond_id", this.pond_id);
        formData.append("date", this.date);
        formData.append("pond_size", this.pond_size);
        formData.append("shrimp_size", this.shrimp_size);
        formData.append("shrimp_number", this.shrimp_number);

        // Update Pond
        if( this.updateid ) {
          await tokenAxios(this.user_info.access_token).patch(
            process.env.VUE_APP_API_SERVER + `/farm/pond/${this.pond.id}/`,
            formData
          );
          // window.alert("Thank you for your interest. We will contact you soon.");
          Swal.fire(
            `Pond ${this.pond_id} is updated`,
            "",
            "success"
          );
        // New Pond 
        } else {
          await tokenAxios(this.user_info.access_token).post(
            process.env.VUE_APP_API_SERVER + `/farm/pond/`,
            formData
          );
          // window.alert("Thank you for your interest. We will contact you soon.");
          Swal.fire(
            `Pond ${this.pond_id} is added`,
            "",
            "success"
          );
        }

        // set Submitted
        this.changeToList();
        this.listPonds(this.farm);

      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },
  }
}
</script>

<style scoped>
.avatar {
  width: 30px;
  margin-right: 15px;
  border-radius: 10px;
}
.farmlist {
  max-width: 300px;
}
.culture-date {
  width: 200px;
}
.farmname {
  color: #04284a;
  font-weight: bold;
  background-color: #d4e3fb;
  padding: 10px 20px;
  min-width: 200px;
  border-radius: 20px;
}
.farm {
  color: #fff;
  background-color: #73afe9;
  border-radius: 3px;
  font-size: 11px;
  padding: 3px;
  margin-left: 3px;
  margin-right: 5px;
}
.farm2 {
  background-color: #fb8f8f
}
.addpond-mobile {
  width: 100%;
}
.uid {
  font-size: 10px;
}
.pond-info {
  font-size: 11px!important;
  min-width: 90px;
}
.newreport {
  padding-left: 12px;
  color: white;
  background-image: radial-gradient(#476d93 90%, transparent 10%);
}
.empty {
  width: 50px;
}
</style>
<style>
.nodata {
  height: 300px!important;
  color: #252536;
  font-size: 18px!important;
  font-weight: bold;
  background-color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}

.farmlist .v-input__slot {
  background-color: #d7e5f4cc!important;
  font-weight: bold;
}

.week {
  color: #fff;
  background-color: #46BFC6;
  border-radius: 10px;
  width: 50px;
  height: 20px;
  padding: 1px 10px;
  /* font-weight: bold; */
  font-size: 12px;
  text-align: center;
}

.harveststarted, .harveststarted:hover {
  background-color: #a8bc93!important;
  color: white!important;;
}
.completed, .completed:hover {
  background-color: #a3a1a1!important;
  color: white;
}
.completed:hover {
  background-color: #767676!important;
}
.duplicated_completed {
  color: #a3a1a1!important;  
}
.duplicated {
  color: #fff!important;  
}
.text-right {
  text-align: right;
  margin: auto;
}
.big-font {
  font-size: 16px;
  margin-right: 2px;
}
.required {
  color: red;
  margin-left: 20px;
}
.newreport .v-label, .newreport .v-icon {
  color: white!important;
}
.sort-field {
  color: #281248;
  cursor: pointer;
}
.sort-field::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.5em;
  vertical-align: middle;
}
.asc::before {
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid #fb6b6b;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
}
.desc::before {
  border-top: 0.5em solid #fb6b6b;
  border-bottom: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
}
.custom-header {
  background-color: #46BFC6; 
}
</style>


