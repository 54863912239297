<template>
<div>
  <!-- new pond -->
  <template v-if="status === 1"> 
    <h2 class="mb-3">Weekly Farm Samples for {{getPond}}</h2>
    <template v-if="isMinnowtech">
      <h4>Assigned to:</h4>
      <v-text-field
          ref="assigned"
          v-model="assigned"
          label=""
          placeholder="assigned"
          solo
          :error-messages="fieldErrors('assigned')"
          @input="$v.assigned.$touch()"
          @blur="$v.assigned.$touch()"
          class="mt-0"
      >
      </v-text-field>
    </template>

    <h4>Sample Week:</h4>
    <v-date-picker v-model="sample_date"></v-date-picker>

    <h4>Sample Size (g):</h4>
    <v-text-field
      ref="size"
      label="samplesize"
      v-model="size"
      placeholder="Please input sample size."
      solo
      :error-messages="fieldErrors('size')"
      @input="$v.size.$touch()"
      @blur="$v.size.$touch()"
      class="mt-0"
    >
    </v-text-field>

    <h4>Temperature (C):</h4>
    <v-text-field
      ref="temperature"
      v-model="temperature"
      label=""
      placeholder="temperature"
      solo
      :error-messages="fieldErrors('temperature')"
      @input="$v.temperature.$touch()"
      @blur="$v.temperature.$touch()"
      class="mt-0"
    >
    </v-text-field>

    <h4>Dissolved Oxygen (mg/L):</h4>
    <v-text-field
      ref="oxygen"
      v-model="oxygen"
      label=""
      placeholder="oxygen"
      solo
      :error-messages="fieldErrors('oxygen')"
      @input="$v.oxygen.$touch()"
      @blur="$v.oxygen.$touch()"
      class="mt-0"
    >
    </v-text-field>

    <h4>Salinity (ppt):</h4>
    <v-text-field
      ref="salinity"
      v-model="salinity"
      label=""
      placeholder="salinity"
      solo
      :error-messages="fieldErrors('salinity')"
      @input="$v.salinity.$touch()"
      @blur="$v.salinity.$touch()"
      class="mt-0"
    >
    </v-text-field>

    <h4>Notes</h4>
    <v-text-field
      v-model="note"
      label="notes"
      placeholder="Please input notes."
      class="mt-0"
    >
    </v-text-field>    
    <v-btn large 
      color="primary"
      class="flex-grow-1"
      @click="addSamples()"
    >
        Submit
    </v-btn>
  </template>
  <!-- week lists -->
  <template v-else>
    <div :class="'d-flex flex-column ' + (isMobile ? '' : 'sample-container')">
      <title-pond></title-pond>
      <div class="mt-5 mb-0 pb-0">
        <pond-menu></pond-menu>
      </div>
      <v-row style="flex: 0 0 24px">
        <v-col cols="12" class="mt-1 text-right py-0 theme--light v-messages">
          {{page}}/{{pages}} Pages
        </v-col>
      </v-row>
      <v-row style="flex: 0 0 60px" v-if="!isMinnowtech" justify="end">
        <v-btn color="primary" @click="status = 1">Add Sample</v-btn>
      </v-row>
      <v-row class="flex-grow-1 flex-shrink-1 scroll full-height">
        <v-simple-table class="mt-0">
          <template #default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Sample Date</th>
                <template v-if="isMinnowtech">
                  <th class="text-left pl-3">Created / 
                  assgined</th>
                <th class="border-left text-left">Count</th>
                <th class="text-left">Biomass</th>
                <th class="text-left">Aerator</th>
                <th class="text-left">Feeder</th>
                <th class="border-right text-left">Maintenance</th>
                </template>
                <th class="text-left">Sample Weight(grams)</th>
                <th class="text-left">Temperature(°C)</th>
                <th class="text-left">Oxygen(mg/L)</th>
                <th class="text-left">Salinity(ppt)</th>
                <!-- <th class="text-left">Notes</th> -->
              </tr>
            </thead>
            <tbody>
              <template v-if="loadingStatus">
                <tr>
                  <td class="nodata" colspan="11" align="center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
              <template v-else-if="!samples_list.length">
                <tr>
                  <td class="nodata" colspan="11" align="center">No Samples</td>
                </tr>
              </template>
              <template v-else v-for="(item, i) in samples_list">
                <template >
                  <tr
                    :key="item.id"
                    :class="item.created_by_name.includes('Minnowtech') && 'minnowtech'"
                    @click="modifySample(item)"
                  >
                    <td>
                        {{(page-1)*20 + (i+1)}}
                    </td>
                    <td>
                      <div class='weekno'>{{item.sample_date | formatDate}}
                        <div v-if="item.sample_time" style="color: #f74141a6" class="d-flex">
                          <v-icon
                            small
                            color="red lighten-3"
                          >
                            mdi-clock-time-four-outline
                          </v-icon>
                          <div class="ml-1 v-messages">
                            {{item.sample_time}}
                          </div>
                        </div>
                      </div>
                      <div class="week mb-1">
                          W{{getWeekNo(item.sample_date)}}
                      </div>
                    </td>
                    <template v-if="isMinnowtech">
                      <td>
                        {{ item.created_by_name}}<br/>
                        <div class="mt-2 d-flex">
                          <div style="min-width: 24px;">
                          <v-icon
                            v-if="item.file"
                            color="blue darken-2"
                            dark
                          >
                            mdi-download
                          </v-icon>
                          </div>
                          <div>
                          <v-tooltip right v-if="item.note">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="orange"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1"
                              >
                                mdi-message-text
                              </v-icon>
                            </template>
                            <span v-html="lineFeed(item.note)"></span>
                          </v-tooltip>
                          </div>
                        </div>
                      </td>
                      <td class="border-left">
                        {{item.count}}
                      </td>
                      <td>
                        {{item.biomass}}
                      </td>
                      <td>
                        <v-checkbox
                          v-model="item.aerator"
                          readonly
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox
                          v-model="item.feeder"
                          readonly
                        ></v-checkbox>
                      </td>
                      <td class="border-right">
                        <v-checkbox
                          v-model="item.maintenance"
                          readonly
                        ></v-checkbox>
                      </td>
                    </template>
                    <td>
                      {{ item.size }}
                    </td>
                    <td>
                      {{ item.temperature }}
                    </td>
                    <td>
                      {{ item.oxygen }}
                    </td>
                    <td>
                      {{ item.salinity }}
                    </td>
                    <!-- <td style="font-size: 0.7rem">
                      {{ item.note }}
                    </td> -->
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
      <v-row style="flex: 0 0 55px" class="justify-center pt-3">
        <v-pagination
          v-model="page"
          :length="pages"
          @input="handlePageChange"
          circle
        ></v-pagination>
      </v-row>
    </div>
  </template>
</div>
</template>

<script>
import { mapState } from "vuex";

import tokenAxios from "@/components/tokenAxios";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
import moment from 'moment'
import PondMenu from './PondMenu'
import TitlePond from "@/components/TitlePond";

export default {
  name: 'Samples',
  computed: {
    ...mapState(["isMobile", "user_info", "isMinnowtech", "pond", "loadingStatus"]), // get user_info and pond from store
  },
  components: {
    PondMenu,
    TitlePond,
  },
  mixins: [validationMixin],
  validations: {
    assigned: {},
    pond: { required },
    week: { required },
    size: { required, decimal },
    temperature: { required, decimal },
    oxygen: { required, decimal },
    salinity: { required, decimal },
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    page: 1,
    pages: 1,
    samples_list: [],
    sample_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), // Add Sample
    status: 0,
    assigned: '',
    week: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    size: null,
    temperature: null,
    oxygen: null,
    salinity: null,
    note: '',
  }),
  created() {
    this.$debug("created", this.pond)
    if( !this.pond ) this.$router.push('/ponds')
    this.listSamples();
  },
  methods: {
    // Error Handling
    fieldErrors(field) {
      this.$debug('fieldErrors', field, this.$v, this.$v[field] )
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },    
    // List Samples
    async listSamples(page) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/samples/?format=json&pond=${this.pond.id}&` + (page ? `&page=${this.page}` : "")
          );
          this.$debug("get weekly samples lists", response);
          if (response.status === 200) {
            this.samples_list = response.data.results;
            this.pages = Math.ceil( response.data.count / 20 );
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },
    // List Samples
    handlePageChange(page) {
      this.listSamples(page);
    },
    // Get week Number for the date 
    getWeekNo(v_date_str) {
      const days = moment(v_date_str).diff(moment(this.pond.date), 'days')+1
      // this.$debug('getWeekNo', moment(v_date_str), moment(this.pond.date), days, Math.ceil(days / 7))
      return Math.ceil(days / 7);
    },
    // Submit samples to server
    async addSamples() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        // focus error field
        for (let key in Object.keys(this.$v)) {
          const input = Object.keys(this.$v)[key];
          this.$debug( "error", input, this.$v, key, "/", this.$v[input], this.$v[input].$error);
          if (input.includes("$")) return false;

          if (this.$v[input].$error) {
            this.$debug( input, this.$refs, this.$refs[input] )
            this.$refs[input].focus();
            if (input === 0) this.$vuetify.goTo(0);
            break;
          }
        }
        return;
      }

      try {
        var formData = new FormData();
        formData.append("pond", this.pond.id);
        formData.append("assigned", this.assigned);
        formData.append("sample_date", this.sample_date);
        formData.append("size", this.size);
        formData.append("temperature", this.temperature);
        formData.append("oxygen", this.oxygen);
        formData.append("salinity", this.salinity);
        formData.append("note", this.note);

        this.$debug("addSamples", formData)

        await tokenAxios(this.user_info.access_token).post(
          process.env.VUE_APP_API_SERVER + `/farm/samples/`,
          formData
        );
        // window.alert("Thank you for your interest. We will contact you soon.");
        Swal.fire(
          `Sample ${this.week} for ${this.getPond} is added`,
          "",
          "success"
        );

        // set Submitted
        this.status = 0;
        this.listSamples();

      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },
    // modify
    modifySample(sample) {
      sample.pond = this.pond
      this.$debug("modifySample", sample)
      this.$router.push({
        name: 'scan',
        params: {
          sample: {...sample}
        },
      });
    },
    // replace new line
    lineFeed(data) {
      return data.replace(/\n/g, "<br>")
    }
  }
}
</script>
<style>
.sample-container {
  min-height: 300px;
}

</style>
<style scoped>

.minnowtech {
  background-color: #f5f5f5;
}
.weekno {
  color: #07124f;
  font-weight: bold;
  margin-right: 20px;
  min-width: 120px;
}
.border-left {
  border-left: 1px solid #e2eaf1
}
.border-right {
  border-right: 1px solid #e2eaf1
}

.scroll {
  overflow: auto;
}
.scroll .v-data-table {
  max-width: none;
  position: relative;
}
.scroll th {
  position: sticky;
  top: 0px;
  z-index: 2;
}

</style>